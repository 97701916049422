import { TAsset } from "../Types/TAsset";
import { TAssetFrame } from "../Types/TAssetFrame";
import { TCategory } from "../Types/TCategory";

export default class CategoryEntity {
  private readonly _id: number;
  private readonly _version: number;
  private readonly _name: string;
  private readonly _image: string;
  private readonly _assets: TAsset[];
  private readonly _atlasJson: string;

  constructor(
    id: number,
    version: number,
    name: string,
    image: string,
    assets: TAsset[],
    atlasJson: string,
  ) {
    this._id = id;
    this._version = version;
    this._name = name;
    this._image = image;
    this._assets = assets;
    this._atlasJson = atlasJson;
  }

  private _frames?: TAssetFrame[] = null;
  get frames(): TAssetFrame[] {
    if (this._frames === null) {
      this._frames = JSON.parse(this._atlasJson).textures[0].frames;
    }
    return this._frames;
  }

  get id(): number {
    return this._id;
  }

  get version(): number {
    return this._version;
  }

  get name(): string {
    return this._name;
  }

  get image(): string {
    return this._image;
  }

  get assets(): TAsset[] {
    return this._assets;
  }

  get toType(): TCategory {
    return {
      id: this.id,
      version: this.version,
      name: this.name,
      image: this.image,
      frames: this.frames,
      assets: this.assets,
      atlasJson: this._atlasJson,
    };
  }
}
