import "reflect-metadata";
import Bugsnag from "@bugsnag/js";
import RestPromise from "../../utils/RestPromise";
import { EPromiseStatus } from "../types/QueueTypes/EPromiseStatus";
import { retry } from "../../utils/RetryOnFail";
import { ApiCallReference } from "./ApiCallReference";

export default class ApiQueue {
  private runningRequests: ApiCallReference[] = [];
  private readonly pausedQueue: ApiCallReference[] = [];

  private static _instance: ApiQueue = null;

  static get instance(): ApiQueue {
    if (this._instance === null) {
      this._instance = new ApiQueue();
    }
    return this._instance;
  }

  /**
   * GET request to a URL.
   * Handle return state or errors.  Can customize the object creation through a callback.
   * This callback can be either a simple (result) => return new Object() or a custom one. Useful on polymorphic objects.
   */
  GetEntity<T>(
    id: number,
    url: string,
    iteration = 0,
    requestDate = new Date(),
  ): Promise<T> {
    const callRef = new ApiCallReference({
      id: id,
      url: url,
      type: "get",
      iteration: iteration,
      date: requestDate,
    });

    // Do not run more than 3 request at the same time
    if (this.runningRequests.length >= 3) {
      callRef.status = EPromiseStatus.PAUSED;
      this.pausedQueue.push(callRef);
    }

    return this.WaitToStart(callRef)
      .then(() => {
        return RestPromise.Create(`${url}/${id}`, "GET");
      })
      .then((result) => {
        this.continueQueue(callRef);

        if (callRef.status == EPromiseStatus.CANCELED) {
          throw {
            text: "Request has been canceled",
            status: EPromiseStatus.CANCELED,
          };
        }

        return result;
      })
      .catch((error) => {
        return this.handleErrors<T>(error, callRef);
      });
  }

  /**
   * This function polls the callReference, if running has been set to true, it resolves. Otherwise, it keeps waiting
   * @param apiCallRef
   * @private
   */
  private async WaitToStart(
    apiCallRef: ApiCallReference,
  ): Promise<ApiCallReference> {
    const poll = (resolve) => {
      if (apiCallRef.status != EPromiseStatus.LOADING) {
        setTimeout(() => poll(resolve), 250);
        return;
      }

      this.runningRequests.push(apiCallRef);

      //Remove the started call from the queue (if it's still in there)
      const index = this.pausedQueue.indexOf(apiCallRef, 0);
      if (this.pausedQueue.indexOf(apiCallRef) > -1) {
        this.pausedQueue.splice(index, 1);
      }

      resolve(apiCallRef);
    };

    return new Promise(poll);
  }

  private continueQueue(thisCall: ApiCallReference) {
    const nextRequest = this.pausedQueue.pop();

    // If there are some paused request left in queue, start the next one
    if (nextRequest !== undefined) {
      nextRequest.status = EPromiseStatus.LOADING;
    }
    this.removeRunningRequest(thisCall);
  }

  private removeRunningRequest(thisCall: ApiCallReference) {
    this.runningRequests = this.runningRequests.filter(
      (x) => x.callId !== thisCall.callId,
    );
  }

  /**
   * Retry the failed request and display information to the user
   * Pause the game after too many failed requests
   * @param error
   * @param callRef
   * @private
   */
  private handleErrors<T>(error, callRef: ApiCallReference): Promise<T> {
    if (
      +error.status == 403 &&
      error.responseText.includes("login_token_invalid")
    ) {
      window.location.href = "/logout?logout-reason=second-login";
      return Promise.reject();
    }

    Bugsnag.notify(error);
    const status = error.status;

    switch (status) {
      case EPromiseStatus.TIMEOUT: {
        console.log("Timeout - Retrying...");
        break;
      }
      case EPromiseStatus.FAILED: {
        console.log("Network error, user potentially offline - Retrying...");
        break;
      }
      case EPromiseStatus.INVALID:
      case EPromiseStatus.CANCELED: {
        throw error;
      }
    }

    if (callRef.iteration >= 1) {
      callRef.status = EPromiseStatus.PAUSED;
      console.log("Game paused");
      return this.establishServerHealth()
        .then(() => {
          return this.rerunApiCall<T>(callRef);
        })
        .catch((error) => {
          //Todo: we lost internet. Inform the user.
          throw error;
        });
    } else {
      return this.rerunApiCall(callRef);
    }
  }

  private rerunApiCall<T>(callRef: ApiCallReference): Promise<T> {
    this.removeRunningRequest(callRef);
    if (callRef.type === "get") {
      return this.GetEntity(
        callRef.id,
        callRef.url,
        callRef.iteration + 1,
        callRef.date,
      );
    }
  }

  private establishServerHealth() {
    return retry<boolean>(
      () =>
        RestPromise.RequestServerHealth().then((result) => {
          if (result == false) {
            throw Error("health check failed");
          }
          return result;
        }),
      { retries: 10, retryIntervalMs: 1000 },
    );
  }
}
