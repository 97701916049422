import NanoPopup from "../components/Popups/NanoPopup";
import "../../resources/less/Troubadour/Freemium/SubscriptionPromotionPopup.less";
import NanoPopupQueue from "../components/Popups/NanoPopupQueue";

export default class SelfPromotionPopup {
  constructor() {
    if ($(".sub-promotion-popup").length === 0) {
      return;
    }
    const popup = new NanoPopup({ target: ".sub-promotion-popup" });
    NanoPopupQueue.instance.Add(popup, 50);
  }
}
