import { v4 as uuid } from "uuid";
import { EPromiseStatus } from "../types/QueueTypes/EPromiseStatus";

export class ApiCallReference {
  private readonly _callId: string;
  private readonly _url: string;
  private readonly _type: string;
  private readonly _id?: number;
  private readonly _worldId?: number;
  private readonly _date: Date;

  constructor({
    url,
    type,
    id,
    date,
    iteration = 0,
  }: {
    url: string;
    type: string;
    id: number;
    date: Date;
    iteration?: number;
  }) {
    //We need to be able to distinguish between otherwise identical calls.
    this._callId = uuid();

    this._url = url;
    this._type = type;
    this._date = date;
    this._iteration = iteration;
    this._id = id;

    this._status = EPromiseStatus.LOADING;
  }

  private _iteration: number;

  get iteration(): number {
    return this._iteration;
  }

  set iteration(value: number) {
    this._iteration = value;
  }

  get callId(): string {
    return this._callId;
  }

  get url(): string {
    return this._url;
  }

  get type(): string {
    return this._type;
  }

  get id(): number {
    return this._id;
  }

  get worldId(): number {
    return this._worldId;
  }

  get date(): Date {
    return this._date;
  }

  private _status: EPromiseStatus;

  get status(): EPromiseStatus {
    return this._status;
  }

  set status(value: EPromiseStatus) {
    this._status = value;
  }
}
