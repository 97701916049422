import RestPromise from "./RestPromise";
import Bugsnag from "@bugsnag/js";
import localforage from "localforage";
import CategoryEntity from "../Troubadour/Assets/CategoryMenu/CategoryEntity";
import { TCategory } from "../Troubadour/Assets/Types/TCategory";
import ApiQueue from "../components/RestQueue/ApiQueue";
import { TEntity } from "../components/types/QueueTypes/TEntity";

/**
 * This class handle cache for our atlas categories in game
 * It should be injected directly in our Main.ts file as we want the queue to be unique for all our js files
 */
export default class CategoryCache {
  private static categoriesDB: typeof localforage;

  constructor() {
    CategoryCache.categoriesDB = localforage.createInstance({
      name: "categories",
    });
  }

  public static RequestCategory(
    categoryId: number,
    version: number,
    callback: (category: CategoryEntity, instanceId: number) => void,
    instanceId: number = null,
  ): void {
    // Request the category to our DB
    CategoryCache.categoriesDB
      .getItem(String(categoryId))
      .then((result: TCategory) => {
        // If the result is null, the category is not in our datable, so we request it to our backend
        if (result === null) {
          return this.getCategory(callback, categoryId, instanceId);
        } else if (
          result.version === undefined ||
          result.version !== +version
        ) {
          // If our category is outdated, remove it from the database and get the new version
          console.log("Updating asset database to get the new version...");
          this.categoriesDB.removeItem(String(categoryId));
          return this.getCategory(callback, categoryId, instanceId);
        }
        const category = new CategoryEntity(
          result.id,
          result.version,
          result.name,
          result.image,
          result.assets,
          result.atlasJson,
        );
        callback(category, instanceId);
        return category;
      })
      .catch((ex) => {
        console.log(ex);
        Bugsnag.notify(ex);
        // If we get an error, that mean that indexedDB is not supported or the user doesn't have enough free space.
        // So we just request it to the server instead of caching it
        return RestPromise.Create(
          `/rest/category/assets/${categoryId}`,
          "GET",
        ).then((result) => {
          return new CategoryEntity(
            result.id,
            result.version,
            result.name,
            result.atlas_image,
            result.assets,
            result.atlas_json,
          );
        });
      });
  }

  private static getCategory(
    callback: (category: CategoryEntity, instanceId: number) => void,
    categoryId: number,
    instanceId: number,
  ) {
    ApiQueue.instance
      .GetEntity<TEntity>(+categoryId, "/rest/category/assets")
      .then((result) => {
        const category = new CategoryEntity(
          result.id,
          result.version,
          result.name,
          result.atlas_image,
          result.assets,
          result.atlas_json,
        );
        callback(category, instanceId);
        return this.categoriesDB.setItem(String(result.id), category.toType);
      });
  }
}
