import "../components/FontAwesomeConfig";
import "../../resources/less/Globals.less";
import "../../resources/less/normalize.less";
import "../../resources/less/Troubadour/troubadourTheme.less";
import "../../resources/less/Troubadour/components/Inputs.less";
import "../../resources/less/components/Buttons/CommonButtons.less";
import "../../resources/less/Troubadour/components/TeacherButtons.less";
import "../../resources/less/Troubadour/components/LoggedOutButtons.less";
import CategoryCache from "../utils/CategoryCache";
import SkippyToast from "../components/SkippyToast";
import CTutorial from "./Tutorial/CTutorial";
import SelfPromotionPopup from "./SelfPromotionPopup";

class TeacherMain {
  constructor() {
    new CategoryCache();
    new CTutorial();
    if ($("#skippytoast-login-elsewhere")) {
      const toast = new SkippyToast("skippytoast-login-elsewhere");
      toast.Show();
    }
    new SelfPromotionPopup();
  }
}

$(window).on("load", () => {
  new TeacherMain();
});
