import NanoPopup from "../../components/Popups/NanoPopup";
import "../../../resources/less/Admin/LoggedIn/troubadourTutorial/TroubadourTutorialPopup.less";
import NanoPopupQueue from "../../components/Popups/NanoPopupQueue";

export default class CTutorial {
  private popup: NanoPopup;
  private alreadyDisplayed = false;

  constructor() {
    const popupDiv = $(".troubadour-tutorial-popup");
    if (popupDiv.length !== 1) {
      return;
    }
    this.popup = new NanoPopup({
      target: ".troubadour-tutorial-popup",
      onHide: () => this.initializeTooltip(),
    });

    if (popupDiv.hasClass("catalog-tutorial-popup")) {
      this.handleCatalogSpecificCase();
    } else {
      if (
        popupDiv.attr("data-auto-display") == "true" &&
        $(".onboarding-popup").length === 0
      ) {
        this.displayTutorialPopup();
      }
    }
    window.addEventListener("onboardingPopupHided", () => {
      this.displayTutorialPopup();
    });
  }

  private initializeTooltip(): void {
    const tooltip = $(".tutorial-tooltip-container");
    if (tooltip.length !== 1) {
      return;
    }
    tooltip.removeClass("hide");
    tooltip.find(".close-button").on("click", () => {
      tooltip.addClass("hide");
    });
    tooltip.find(".tooltip-backpanel").on("click", () => {
      tooltip.addClass("hide");
    });
  }

  /**
   * As the catalog is on the same page that the challenge creation,
   * only display the tutorial if we actually are on the catalog section of the page
   * @private
   */
  private handleCatalogSpecificCase(): void {
    const catalogButton = $(".template-button");
    if (window.location.href.includes("displayTemplate=1")) {
      this.displayTutorialPopup();
    }
    catalogButton.on("click.tutorial", () => {
      this.displayTutorialPopup();
      catalogButton.off("click.tutorial");
    });
  }

  private displayTutorialPopup(): void {
    if (this.alreadyDisplayed) {
      return;
    }
    NanoPopupQueue.instance.Add(this.popup, 45);
    this.alreadyDisplayed = true;
  }
}
